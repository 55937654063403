<template>
    <admin-dashboard-layout :loading="loading">
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        {{pricePlan.name}}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.price-plans.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-text-field label="Name"  v-model="pricePlan.name"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Monthly Price"
                                          v-model="pricePlan.monthly_price"
                                          :disabled="!!pricePlan.id"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select
                                v-model="pricePlan.industry_id"
                                label="Industry"
                                item-text="name"
                                item-value="id"
                                :items="industries"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-switch label="Active" v-model="pricePlan.is_active"  />
                        </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="text-center">
                        <v-btn v-if="$route.params.id" @click="handleDelete" color="red" v-text="'Delete'"/>
                      </v-col>
                        <v-col class="text-center">
                            <v-btn @click="handleSave" v-text="'Save'"/>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
    import PricePlan from "../../../models/PricePlan";
    import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
    import Industry from "@/models/Industry";
    import {mapMutations} from "vuex";

    export default {
        name: "users-show",
        components: {AdminDashboardLayout},
        data: function () {
            return {
                loading: true,
                pricePlan: {},
                industries: []
            }
        },
        methods:{
            async handleSave() {
                const pricePlan = new PricePlan(this.pricePlan)
                this.pricePlan = await pricePlan.save()
                this.showSnackBar({color: 'success', timeout: 3000, text: 'Saved successfully'})

                if (this.pricePlan.id && !this.$route.params.id) {
                    await this.$router.push({name: 'admin.price-plans.edit', params: {id: this.pricePlan.id}})
                }
            },
            async getIndustries() {
                this.industries = await Industry.get();

                if (!this.pricePlan.industry_id) {
                    this.pricePlan.industry_id = this.industries[0].id
                }
            },
            async handleDelete() {
                let plan = await PricePlan.find(this.$route.params.id)

                await plan.delete()
                this.showSnackBar({color: 'success', timeout: 3000, text: 'Saved successfully'})

                this.$router.push({name: 'admin.price-plans.index'})
            },
            ...mapMutations(['showSnackBar']),
        },
        async mounted() {
            await this.getIndustries();

            if (this.$route.params.id) {
                const pricePlanId = this.$route.params.id
                this.pricePlan = await PricePlan.find(pricePlanId)
            }

            this.loading = false;
        },
    }
</script>

<style scoped>

</style>
